import { CircularProgress, FormControl, FormHelperText } from '@mui/material';
import { SystemStyleObject, Theme } from '@mui/system';
import CustomExpandIcon from 'components/SVGIcons/CustomExpandIcon';
import get from 'lodash/get';
import { cloneElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { Placeholder } from '../CustomMultipleChipsSelect/CustomMultipleChipsSelect.styled';
import {
    LoaderWrapper,
    StyledLabel,
    StyledMenuItem,
    StyledSelect,
} from './CustomSelectInput.styled';

interface SelectInputProps {
    itemsToSelect?: {
        value: number | string;
        display_name: string;
    }[];
    label?: string;
    formKey: string;
    disabled?: boolean;
    setFormCode?: boolean;
    multiple?: boolean;
    borderRadius?: string;
    sx?: React.CSSProperties | SystemStyleObject<Theme>;
    menuItemStyle?: React.CSSProperties | SystemStyleObject<Theme>;
    placeholderStyle?: React.CSSProperties | SystemStyleObject<Theme>;
    isLoading?: boolean;
    placeholder?: string;
    staticPlaceholder?: string;
    CustomIcon?: ReactElement;
    emptyListMessage?: string;
}

const MenuProps = {
    PaperProps: { sx: { maxHeight: 400 } },
    MenuListProps: { sx: { padding: 0 } },
};

const CustomSelectInput: React.FC<SelectInputProps> = ({
    itemsToSelect = [],
    label,
    formKey,
    disabled = false,
    multiple = false,
    borderRadius,
    sx,
    menuItemStyle,
    placeholderStyle,
    isLoading,
    placeholder,
    emptyListMessage,
    staticPlaceholder,
    CustomIcon = (
        <CustomExpandIcon sx={{ width: '10px', marginRight: '10px' }} />
    ),
}) => {
    const {
        register,
        watch,
        trigger,
        formState: { errors, isSubmitting },
    } = useFormContext();
    const value = watch(formKey);
    const triggerErrorMessage = () => trigger(formKey);

    const errorMessage = get(errors, formKey)?.message;

    return (
        <FormControl fullWidth>
            <StyledLabel id={formKey} shrink error={!!errorMessage}>
                {label}
            </StyledLabel>

            <StyledSelect
                labelId={formKey}
                {...register(formKey)}
                error={!!errorMessage}
                label={label}
                value={String(value)}
                disabled={disabled || isSubmitting}
                multiple={multiple}
                onBlur={triggerErrorMessage}
                IconComponent={(props) => cloneElement(CustomIcon, props)}
                sx={{ borderRadius, ...sx }}
                MenuProps={MenuProps}
                displayEmpty
                renderValue={(selected) => {
                    if (!selected && placeholder)
                        return (
                            <Placeholder sx={placeholderStyle}>
                                {placeholder}
                            </Placeholder>
                        );

                    const displayName = itemsToSelect.find(
                        ({ value }) => String(value) === String(selected)
                    )?.display_name;

                    if (staticPlaceholder)
                        return `${staticPlaceholder} ${displayName}`;

                    return displayName;
                }}
                notched>
                {isLoading && (
                    <LoaderWrapper>
                        <CircularProgress />
                    </LoaderWrapper>
                )}

                {itemsToSelect.length === 0 && (
                    <StyledMenuItem value="" disabled sx={menuItemStyle}>
                        {emptyListMessage ?? 'No options available'}
                    </StyledMenuItem>
                )}

                {itemsToSelect.map(({ value, display_name }) => (
                    <StyledMenuItem
                        value={value}
                        key={value}
                        sx={menuItemStyle}>
                        {display_name}
                    </StyledMenuItem>
                ))}
            </StyledSelect>

            <FormHelperText error={!!errorMessage}>
                {errorMessage}
            </FormHelperText>
        </FormControl>
    );
};

export default CustomSelectInput;
