import CameraIcon from 'components/SVGIcons/CameraIcon';
import { InfoSection, PhotoContainer, Wrapper } from './AddCompanyLogo.styled';
import { theme } from '@theme/theme';
import { useFileUpload } from '@hooks/useFileUpload';
import Image from 'next/image';

const { secondaryColor_2 } = theme.colors;

interface Props {
    formKey: string;
}

const AddCompanyLogo = ({ formKey }: Props) => {
    const { browseFileHandler, inputRef, handleChange, imagePreviewUrl } =
        useFileUpload({ formKey });

    return (
        <Wrapper>
            <PhotoContainer onClick={browseFileHandler}>
                {!imagePreviewUrl && (
                    <>
                        <CameraIcon width={64} color={secondaryColor_2} />
                        Add Photo
                    </>
                )}

                {imagePreviewUrl && (
                    <Image
                        src={imagePreviewUrl}
                        layout="fill"
                        objectFit="contain"
                    />
                )}
            </PhotoContainer>

            <InfoSection>
                <h2>Add company logo</h2>
                <span>
                    Your company logo will not be visible until the professional
                    is invited by you.
                </span>
            </InfoSection>

            <input
                style={{ display: 'none' }}
                ref={inputRef}
                type="file"
                multiple={false}
                onChange={handleChange}
            />
        </Wrapper>
    );
};

export default AddCompanyLogo;
