import { useState } from 'react';
import {
    StyledButton,
    StyledMenu,
    ArrowUp,
    ArrowDown,
} from './DropDown.styled';

interface IDropDownProps {
    title: string;
}

const DropDown: React.FC<IDropDownProps> = ({ children, title }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <StyledButton
                $opened={isOpen}
                aria-controls={isOpen ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={isOpen}
                onClick={handleClick}
                endIcon={isOpen ? <ArrowUp /> : <ArrowDown />}>
                {title}
            </StyledButton>

            <StyledMenu
                anchorEl={anchorEl}
                open={isOpen}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                {children}
            </StyledMenu>
        </>
    );
};

export default DropDown;
