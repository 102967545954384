import { useMemo, useState } from 'react';
import { useUserData } from 'state/userData';
import { ProfileMenuItems } from 'types/componentTypes';
import {
    PopoverBody,
    CustomPopover,
    UserTypeLabel,
    UserName,
    StyledButton,
    LogOutButton,
    BecomePartner,
    ItemsWrapper,
    Wrapper,
    CompanyImageWrapper,
    ProfileCircleImageWrapper,
    ModalProfileImageWrapper,
} from './ProfileMenu.styled';
import CustomLink from 'components/CustomLink';
import useApiUrl from 'hooks/useApiUrl';
import useUserType from 'hooks/useUserType';
import sendRequestToBecomePartner from 'api/sendRequestToBecomePartner';
import { useRouter } from 'next/router';
import useClientRuntimeConfig from 'state/useClientRuntimeConfig';
import LogOutIcon from 'components/SVGIcons/Settings/LogOutIcon';
import { clearUtmStorage } from '@hooks/useUTMtags';
import Image from 'next/image';
import { Box, Divider } from '@mui/material';
import axiosServerError from '@helpers/axiosServerError';
import OrganizationSelect from '../OrganizationSelect/OrganizationSelect';
import { FeatureFlagKeys } from 'constants/featureFlagKeys';
import { useFlag } from '@unleash/proxy-client-react';
import { toastSuccess } from 'components/CustomToast/customToastFunctions';

const { createNewOrganization } = FeatureFlagKeys;

const disabledButtons = ['My profile'];
const successMessage = 'Request to become a partner has been sent to expert';

const ProfileMenu: React.FC<ProfileMenuItems> = ({ items, icon }) => {
    const router = useRouter();
    const [anchorEl, setAnchorEl] = useState<HTMLImageElement | null>(null);
    const apiURL = useApiUrl();
    const open = Boolean(anchorEl);
    const { isClient, isTalent } = useUserType();

    const withOrganizations = useFlag(createNewOrganization);

    const { config } = useClientRuntimeConfig();
    const { NYLAS_MEETING_PLANNING_LINK } = config;

    const { user } = useUserData();
    const { setLoginStatus } = useUserData();
    const avatarUrl = icon ? `${apiURL}${icon}` : '/img/userProfileIcon.png';
    const userRole = user.role;
    const companyLogoUrl = user.activeCompany?.photo?.path;

    const handleClose = () => setAnchorEl(null);
    const handleLogOut = () => {
        setLoginStatus(false);
        clearUtmStorage();
    };

    const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleBecomePartner = async () => {
        try {
            await sendRequestToBecomePartner();
            toastSuccess(successMessage);
            router.push(NYLAS_MEETING_PLANNING_LINK || '/');
        } catch (error) {
            axiosServerError({ error });
        }
    };

    const userType = useMemo(
        () => user?.typeGroups?.at(0) || '',
        [user.typeGroups]
    );

    const OrganizationInfo = () => {
        if (!withOrganizations) return null;
        if (!isClient) return null;

        return (
            <>
                <Box display="flex" alignItems="center" gap="6px">
                    {!!companyLogoUrl && (
                        <CompanyImageWrapper>
                            <Image
                                src={`${apiURL}${companyLogoUrl}`}
                                objectFit="contain"
                                layout="fill"
                            />
                        </CompanyImageWrapper>
                    )}

                    <span>{user.activeCompany?.title}</span>
                </Box>

                <Divider
                    sx={{ height: '32px', width: '1px' }}
                    orientation="vertical"
                    flexItem
                />
            </>
        );
    };

    return (
        <>
            <Wrapper onClick={handleClick} $isOpened={!!anchorEl}>
                <OrganizationInfo />

                <ProfileCircleImageWrapper>
                    <Image src={avatarUrl} objectFit="cover" layout="fill" />
                </ProfileCircleImageWrapper>
            </Wrapper>

            <CustomPopover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <PopoverBody>
                    <ModalProfileImageWrapper>
                        <UserTypeLabel>{userType}</UserTypeLabel>

                        <ModalProfileImageWrapper
                            sx={{ border: '5px solid white' }}>
                            <Image
                                src={avatarUrl}
                                objectFit="cover"
                                layout="fill"
                            />
                        </ModalProfileImageWrapper>
                    </ModalProfileImageWrapper>

                    <UserName>
                        {user.firstName} {user.lastName}
                    </UserName>

                    {userRole === 'professional' && false && (
                        <BecomePartner onClick={handleBecomePartner}>
                            Upgrade to Partner
                        </BecomePartner>
                    )}

                    {withOrganizations && <OrganizationSelect />}

                    <ItemsWrapper>
                        {items?.map((el) => {
                            const isDisabled =
                                disabledButtons.includes(el.title) && isClient;

                            switch (el.type) {
                                case 'profile_menu_item': {
                                    const isSettings = el.title === 'Settings';

                                    const url = () => {
                                        if (!isSettings) return el.url;

                                        const clientSettings = `${el.url}company-profile/`;
                                        const talentSettings = `${el.url}financial-settings/`;

                                        if (isClient) return clientSettings;
                                        if (isTalent) return talentSettings;
                                        return el.url;
                                    };

                                    return (
                                        <CustomLink
                                            fullWidth
                                            key={el.id}
                                            href={`${apiURL}${url()}`}
                                            disabled={isDisabled}>
                                            <StyledButton
                                                key={el.id}
                                                disabled={isDisabled}>
                                                {el.title}
                                            </StyledButton>
                                        </CustomLink>
                                    );
                                }

                                case 'action':
                                    return (
                                        <a
                                            href={`${apiURL}${el.action.url}`}
                                            key={el.id}>
                                            <LogOutButton
                                                onClick={handleLogOut}>
                                                <LogOutIcon
                                                    width={24}
                                                    height={24}
                                                />
                                                {el.title}
                                            </LogOutButton>
                                        </a>
                                    );
                                default:
                                    return null;
                            }
                        })}
                    </ItemsWrapper>
                </PopoverBody>
            </CustomPopover>
        </>
    );
};

export default ProfileMenu;
