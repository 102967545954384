import getPageByPath from 'api/getPageByPath';
import { localStorageKeys } from 'constants/localStorageKeys';
import { useEffect, useState } from 'react';
import { useUserData } from 'state/userData';
import useUserType from '@hooks/useUserType';
import useSWR from 'swr';
import { ComponentHeader } from 'types/componentTypes';
import { isEqual } from 'lodash';
import camelcaseKeys from 'camelcase-keys';

const { storedHeaderKey } = localStorageKeys;

interface Response {
    component_name: string;
    component_props: { header: ComponentHeader };
}

type Header = { [key: string]: ComponentHeader | undefined };

interface HeaderStopeProps {
    client: Header;
    talent: Header;
    expert: Header;
    anonymous: ComponentHeader | undefined;
}

const useStoredHeader = () => {
    const [headerProps, setHeaderProps] = useState<ComponentHeader | undefined>(
        undefined
    );

    const { isClient, isExpert, isTalent } = useUserType();

    const { isLoggedIn, user } = useUserData();
    const slug = user.profile?.slug;

    const headerFetcher = async () => {
        const { data } = await getPageByPath<Response>({ url: '/' });
        const header = data.component_props.header;

        const modifiedHeader = camelcaseKeys(header, { deep: true });

        const store = localStorage.getItem(storedHeaderKey) || '{}';
        const storageHeader = JSON.parse(store);

        const headerData: HeaderStopeProps = {
            client: {},
            talent: {},
            expert: {},
            anonymous: {},
            ...storageHeader,
        };

        if (isClient && slug) headerData.client[slug] = modifiedHeader;
        if (isTalent && slug) headerData.talent[slug] = modifiedHeader;
        if (isExpert && slug) headerData.expert[slug] = modifiedHeader;
        if (!isLoggedIn || !slug) headerData.anonymous = modifiedHeader;

        localStorage.setItem(storedHeaderKey, JSON.stringify(headerData));

        return modifiedHeader;
    };

    const { data } = useSWR(`header/${isLoggedIn}`, headerFetcher, {
        revalidateOnFocus: false,
    });

    const getStoredHeader = () => {
        const header = localStorage.getItem(storedHeaderKey);
        if (!header) return;

        const storageHeader: HeaderStopeProps = JSON.parse(header);

        const { client, talent, anonymous, expert } = storageHeader;

        if (!isLoggedIn || !slug) return anonymous;

        if (isClient && slug in client) return client[slug];

        if (isTalent && slug in talent) return talent[slug];

        if (isExpert && slug in expert) return expert[slug];

        return;
    };

    useEffect(() => {
        if (!global.window) return;

        const storedHeader = getStoredHeader();

        const isStoreUpToDate = isEqual(storedHeader, headerProps);

        if (isStoreUpToDate) return;

        setHeaderProps(storedHeader);
    }, [isLoggedIn, data, slug, global.window]);

    return { headerProps };
};

export default useStoredHeader;
